<template>
	<li
		class="member"
		style="display: table; width: 100%">
		<div class="p-2 bg-light border-bottom border-secondary">
			<!-- Row container -->
			<div class="row flex-nowrap justify-content-between">
				<!-- ID/Username -->
				<div
					:class="{bold: isFolder, pointer: isFolder}"
					class="col my-auto"
					@click="toggle($event)">
					<div class="row flex-nowrap">
						<div class="col">
							<div class="row flex-nowrap align-items-center">
								<div class="col-auto">
									<i
										v-if="isFolder"
										:class="open ? 'fa-chevron-down' : 'fa-chevron-right'"
										class="fa mr-1" />
									<template v-else>
										<i
											v-if="!checkedForChildrens && ( model.num_children && !model.children )"
											class="fa fa-plus pointer"
											@click="changeType" />
									</template>
								</div>
								<div class="col-auto px-0">
									<i
										v-if="!model.is_unauthorized"
										:class="findColor(model.status)"
										class="fa fa-user mr-2" />
								</div>
								<div class="col-auto px-0">
									<b-badge
										v-if="admin.includes($user.details().type) && !model.is_unauthorized"
										variant="primary"
										class="pointer uid-pill"
										@click="toDashboard({ distributorId: model.distributor_id.toString() })">
										{{ model.distributor_id }}
									</b-badge>
									<b-badge
										v-else
										variant="primary">
										{{ model.is_unauthorized ? translate('NA') : model.distributor_id }}
									</b-badge>
								</div>
								<div class="col-auto px-0">
									<b-badge
										class="ml-1"
										variant="dark">
										{{ model.is_unauthorized ? translate('unauthorized').toUpperCase() : model.username }}
									</b-badge>
									<span
										v-if="model.has_ticket && model.has_enabled_ticket"
										v-b-tooltip.hover
										:title="translate('has_purchased_ticket')"
										style="vertical-align: text-top;margin-left:5px;">
										<i class="fa fa-ticket primary-color" />
									</span>
									<span
										v-if="model.has_ticket && !model.has_enabled_ticket"
										v-b-tooltip.hover
										:title="translate('has_pending_ticket')"
										style="vertical-align: text-top;margin-left:5px;">
										<i class="fa fa-ticket text-muted" />
									</span>
								</div>
								<div
									v-if="!model.is_unauthorized"
									class="col-auto px-0">
									<b-button
										:id="`popover-${model.distributor_id}`"
										class="unilevel-popover"
										variant="link"
										@mouseenter="getUserInfo(model.distributor_id)">
										<i class="fas fa-plus text-primary" />
									</b-button>
									<b-popover
										:target="`popover-${model.distributor_id}`"
										triggers="hover"
										:placement="['xs','sm'].includes(windowWidth) ? 'bottom' : 'auto'">
										<template v-slot:title>
											<div class="w-100 d-flex align-items-center">
												<div class="mr-1">
													{{ translate('member_information') }}
												</div>
												<img
													v-if="userInfo.is_rising_star"
													v-b-tooltip.hover
													:title="translate('rising_star')"
													style="vertical-align: text-top;"
													:src="`${S3_PATH}/assets/ranks/${themeName}/rising_star_20x20_green.png`">
												<span
													v-if="userInfo.has_ticket && userInfo.has_enabled_ticket"
													v-b-tooltip.hover
													:title="translate('has_purchased_ticket')"
													style="vertical-align: text-top;margin-left:5px;">
													<i class="fa fa-ticket primary-color" />
												</span>
												<span
													v-if="userInfo.has_ticket && !userInfo.has_enabled_ticket"
													v-b-tooltip.hover
													:title="translate('has_pending_ticket')"
													style="vertical-align: text-top;margin-left:5px;">
													<i class="fa fa-ticket text-muted" />
												</span>
											</div>
										</template>
										<div>
											<div>
												<p
													v-if="userInfo.sponsor_id && userInfo.sponsor_username"
													class="mb-0">
													<b>{{ translate('sponsor') }}: </b>
													<span
														v-if="admin.includes($user.details().type)"
														class="btn-link exchange-text pointer"
														@click="toDashboard({ distributorId: userInfo.sponsor_id.toString() })">
														{{ userInfo.sponsor_id }} - {{ userInfo.sponsor_username }}
													</span>
													<span v-else>
														{{ userInfo.sponsor_username }}
													</span>
												</p>
												<p
													v-else
													class="mb-0">
													<b>{{ translate('sponsor') }}: </b>
													<span>
														{{ userInfo.sponsor_username }}
													</span>
												</p>
												<p
													v-if="userInfo.status"
													class="mb-0">
													<b>{{ translate('status') }}: </b>{{ translate(userInfo.status) }}
												</p>
												<p
													v-if="userInfo.permanent_rank"
													class="mb-0 d-flex align-items-center">
													<b class="mr-1">{{ translate('permanent_rank') }}: </b>{{ translate(userInfo.permanent_rank) }}
													<template v-if="subRankIndicator(userInfo, 'permanent_sub_rank') !== null">
														<img
															:src="`${S3_PATH}/assets/ranks/${themeName}/${subRankIndicator(userInfo, 'permanent_sub_rank')}_indicator.png`"
															width="15px"
															class="ml-1"
															style="margin-bottom: 3px"
															alt="sub_rank">
													</template>
												</p>
												<p
													v-if="userInfo.rank"
													class="mb-0 d-flex align-items-center">
													<span class="font-weight-bold mr-1">{{ translate('rank') }}</span> ({{ translate('week_number', {number: userInfo.rank_week}) }}): {{ translate(userInfo.rank) }}
													<template v-if="subRankIndicator(userInfo, 'current_sub_rank') !== null">
														<img
															:src="`${S3_PATH}/assets/ranks/${themeName}/${subRankIndicator(userInfo, 'current_sub_rank')}_indicator.png`"
															width="15px"
															class="ml-1"
															style="margin-bottom: 3px"
															alt="sub_rank">
													</template>
												</p>
												<p
													v-if="userInfo.next_purchase_date && isActive"
													class="mb-0">
													<b>{{ translate('active_until') }}: </b>{{ this.$moment(userInfo.next_purchase_date.date).format(dateFormat) }}
												</p>
											</div>
											<div>
												<div
													v-if="userUnilevelVolume && Object.keys(userUnilevelVolume).length"
													class="table-responsive mb-0">
													<p class="mt-2 mb-0">
														<b>{{ translate('unilevel_volume') }}:</b>
													</p>
													<table class="table text-nowrap">
														<thead>
															<tr class="text-center">
																<th class="p-2 align-middle">
																	{{ translate('week') }}
																</th>
																<th class="p-2 align-middle">
																	{{ translate('period') }}
																</th>
																<th
																	:class="{'d-none' : ['xs','sm'].includes(windowWidth)}"
																	class="p-2 align-middle">
																	{{ translate('left_bv') }}
																</th>
																<th
																	:class="{'d-none' : ['xs','sm'].includes(windowWidth)}"
																	class="p-2 align-middle">
																	{{ translate('right_bv') }}
																</th>
																<th class="p-2 align-middle">
																	{{ translate('total') }}
																</th>
															</tr>
														</thead>
														<tbody>
															<tr
																v-for="(item) in userUnilevelVolume.weeks"
																:key="item.weeks">
																<td class="p-2 align-middle">
																	{{ item.week_number }}
																</td>
																<td class="p-2 align-middle">
																	{{ $moment(item.start_date.date).format(popoverDateFormat) }} - {{ $moment(item.end_date.date).format(popoverDateFormat) }}
																</td>
																<td
																	:class="{'d-none' : ['xs','sm'].includes(windowWidth)}"
																	class="p-2 align-middle text-center">
																	{{ item.left_bv }}
																</td>
																<td
																	:class="{'d-none' : ['xs','sm'].includes(windowWidth)}"
																	class="p-2 align-middle text-center">
																	{{ item.right_bv }}
																</td>
																<td class="p-2 align-middle text-center">
																	{{ item.total }}
																</td>
															</tr>
														</tbody>
													</table>
												</div>
											</div>
										</div>
									</b-popover>
								</div>
								<div
									:class="{'px-0': !model.is_unauthorized, 'px-3': model.is_unauthorized}"
									class="col-auto">
									<div
										v-if="['md','lg', 'xl'].includes(windowWidth)"
										class="d-inline">
										<span v-if="model.num_children === 0">
											{{ translate('no_members') }}
										</span>
										<span v-else-if="model.num_children === 1">
											{{ translate('one_member', {'qty': model.num_children }) }}
										</span>
										<span v-else>
											{{ translate('many_members', {'qty': model.num_children }) }}
										</span>
									</div>
									<div
										v-else
										class="d-inline">
										<span>{{ model.num_children }}</span>
									</div>
								</div>
							</div>
						</div>
						<div class="col ml-3">
							<div
								v-if="!model.is_unauthorized"
								class="d-flex justify-content-end pt-2">
								<div class="buttons-width text-center">
									{{ translate(model.highest_rank) }}
									<template v-if="subRankIndicator(model, 'permanent_sub_rank') !== null">
										<img
											:src="`${S3_PATH}/assets/ranks/${themeName}/${subRankIndicator(model, 'permanent_sub_rank')}_indicator.png`"
											class="mb-2"
											alt="sub_rank">
									</template>
								</div>
								<div class="buttons-width text-center">
									{{ translate(model.rank_progress) }}
									<template v-if="subRankIndicator(model, 'current_sub_rank') !== null">
										<img
											:src="`${S3_PATH}/assets/ranks/${themeName}/${subRankIndicator(model, 'current_sub_rank')}_indicator.png`"
											class="mb-2"
											alt="sub_rank">
									</template>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<ul
			v-show="open"
			v-if="isFolder"
			class="dashed-border-left">
			<child
				v-for="(modelChild, index) in model.children"
				:key="index"
				:model="modelChild"
				class="item" />
		</ul>
	</li>
</template>
<script>
import Vue from 'vue';
import WindowSize from '@/mixins/WindowSizes';
import DashboardRedirect from '@/mixins/DashboardRedirect';
import SubRanks from '@/mixins/SubRanks';
import UnilevelTree from '@/util/UnilevelTree';
import {
	Ranks,
	Packages,
	Network,
	Events,
	Users,
} from '@/translations';
import { MDY_FORMAT, MMDD_FORMAT } from '@/settings/Dates';
import { admin } from '@/settings/Roles';
import TreeMixin from '../mixins/Tree';
import { S3_PATH } from '@/settings/Images';

export default {
	messages: [Ranks, Packages, Network, Events, Users],
	name: 'UnilevelTreeItem',
	mixins: [WindowSize, TreeMixin, DashboardRedirect, SubRanks],
	props: {
		model: {
			type: Object,
			default() {
				return {};
			},
		},
		firstLevel: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			checkedForChildrens: false,
			open: false,
			myUnilevel: new UnilevelTree(),
			unilevelInfo: new UnilevelTree(),
			dateFormat: MDY_FORMAT,
			popoverDateFormat: MMDD_FORMAT,
			admin,
			themeName: process.env.VUE_APP_THEME,
			S3_PATH,
		};
	},
	computed: {
		isFolder() {
			return this.model.children && this.model.children.length;
		},
		unilevel() {
			try {
				const [tree] = this.myUnilevel.data.response.data.data.attributes;
				if (tree) {
					return tree;
				}
				return {};
			} catch (error) {
				return {};
			}
		},
		userInfo() {
			try {
				const data = this.unilevelInfo.data.response.data.data.attributes;
				return data;
			} catch (error) {
				return {};
			}
		},
		userUnilevelVolume() {
			try {
				const unilevelVolume = this.userInfo.unilevel_volume;
				return unilevelVolume;
			} catch (error) {
				return {};
			}
		},
		isActive() {
			return this.userInfo.status === 'active';
		},
	},
	mounted() {
		if (this.firstLevel) {
			this.toggle();
		}
	},
	methods: {
		toggle(e = '') {
			const clickPopover = e.target && (e.target.classList.contains('fa-plus') || e.target.classList.contains('unilevel-popover') || e.target.classList.contains('uid-pill'));
			if (this.isFolder && !clickPopover) {
				this.open = !this.open;
			}
		},
		loadTree(id) {
			this.myUnilevel.getTree({ id }).then(() => {
				Vue.set(this.model, 'children', this.unilevel.children);
			});
		},
		changeType() {
			if (!this.isFolder && !this.checkedForChildrens) {
				this.checkedForChildrens = true;
				this.loadTree(this.model.distributor_id);
				this.open = true;
			}
		},
		countNode(num) {
			let n = num;
			if (typeof this.$parent.countNode === 'function') {
				n = this.$parent.countNode(n);
			}
			return n + 1;
		},
		showModal(userId, username) {
			this.$parent.showModal(userId, username);
		},
		getUserInfo(userId) {
			const { placement } = this.$route.query;
			const options = { placement, id: userId };
			this.unilevelInfo.getInfo(options);
		},
	},
};
</script>
<style>
	.small-text {
		font-size: 0.8em;
	}
	.member{
		list-style: none;
	}
	.dashed-border-left{
		border-left: 2px dashed lightgray;
	}
	.tabs .tab-content {
		border: none;
	}
	.buttons-width{
		width: 165px;
	}
	.popover.b-popover{
		max-width: 100%;
		z-index: 100;
	}
	.uid-pill {
		z-index: 100;
	}
	.popover.b-popover .popover-body{
		box-shadow: 0 0 transparent;
	}
	@media only screen and (max-width: 576px) {
		.popover.b-popover{
			max-width: 262px;
		}
		.popover.b-popover .popover-body{
			height: 100%;
			overflow: scroll;
		}
	}
</style>
