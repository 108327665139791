import { GET_UNILEVEL_TREE, GET_UNILEVEL_INFO } from '@/config/endpoint';
import Req from './AxiosRequest';

class UnilevelTree {
	constructor() {
		this.data = new Req();
		this.errors = this.data.errors;
	}

	clear() {
		this.data.clear();
	}

	getTree(options) {
		const { method, endpoint } = GET_UNILEVEL_TREE;
		const queryParams = Req.setFilters(options);
		return this.data[method](endpoint(queryParams)).then((response) => response).catch((error) => {
			this.data.loading = false;
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getInfo(options) {
		const { method, endpoint } = GET_UNILEVEL_INFO;
		const queryParams = Req.setFilters(options);
		return this.data[method](endpoint(queryParams)).then((response) => response).catch((error) => {
			this.data.loading = false;
			this.errors.record(error);
			return Promise.reject(error);
		});
	}
}

export default UnilevelTree;
